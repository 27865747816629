import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index.vue'),
  },
  {
    path: '/home/index',
    name: 'home',
    component: () => import('../views/home.vue'),
    meta: {
      keepAlive: true //设置页面是否需要使用缓存
  },
    children: [
      {
        path: '/home/index',
        component: () => import('../views/home/index.vue'),
      },
      {
        path: '/home/book',
        component: () => import('../views/home/book.vue'),
      },
      {
        path: '/home/my',
        component: () => import('../views/home/my.vue'),
      }
    ]
  },
  {
    path: '/book/RankRank',
    name: 'bookRankRank',
    component: () => import('../views/book/RankRank.vue'),
  },
  {
    path: '/book/rankDetail',
    name: 'bookrankDetail',
    component: () => import('../views/book/rankDetail.vue'),
  },
  {
    path: '/book/stack',
    name: 'bookstack',
    component: () => import('../views/book/stack.vue'),
  },

  {
    path: '/book/toPay',
    name: 'booktoPay',
    component: () => import('../views/book/toPay.vue'),
  },
  {
    path: '/book/toReward',
    name: 'booktoReward',
    component: () => import('../views/book/toReward.vue'),
  },
  {
    path: '/book/toRead',
    name: 'booktoRead',
    component: () => import('../views/book/toRead.vue'),
  },
  {
    path: '/book/search',
    name: 'booksearch',
    component: () => import('../views/book/search.vue'),
  },
  {
    path: '/book/info',
    name: 'bookinfo',
    component: () => import('../views/book/info/info.vue'),
    children: [
      {
        path: '/book/info/infoA',
        component: () => import('../views/book/info/infoA.vue'),
      },
      {
        path: '/book/info/infoB',
        component: () => import('../views/book/info/infoB.vue'),
      },
      {
        path: '/book/info/infoC',
        component: () => import('../views/book/info/infoC.vue'),
      },
    ]
  },
  {
    path: '/book/info/goToComment',
    name: 'bookinfogoToComment',
    component: () => import('../views/book/info/goToComment.vue'),
  },
  {
    path: '/book/info/getBookRecommend',
    name: 'bookinfogetBookRecommend',
    component: () => import('../views/book/info/getBookRecommend.vue'),
  },
  {
    path: '/book/getPayRecords',
    name: 'bookgetPayRecords',
    component: () => import('../views/book/getPayRecords.vue'),
  },
  {
    path: '/book/dsRecords',
    name: 'bookdsRecords',
    component: () => import('../views/book/dsRecords.vue'),
  },
  {
    path: '/book/getUserComplainByUserId',
    name: 'bookgetUserComplainByUserId',
    component: () => import('../views/book/getUserComplainByUserId.vue'),
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('../views/home/my.vue'),
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue'),
  },
  {
    path: '/user/settings',
    name: 'usersettings',
    component: () => import('../views/user/settings.vue'),
  },
  {
    path: '/user/addUserComplain',
    name: 'useraddUserComplain',
    component: () => import('../views/user/addUserComplain.vue'),
  },
  {
    path: '/user/security',
    name: 'usersecurity',
    component: () => import('../views/user/security.vue'),
  },
  {
    path: '/login/entryByUsername',
    name: 'entryByUsername',
    component: () => import('../views/login/entryByUsername.vue')
  },
  {
    path: '/login/toUserAnswer',
    name: 'toUserAnswer',
    component: () => import('../views/login/toUserAnswer.vue')
  },
  {
    path: '/login/toRegister',
    name: 'toRegister',
    component: () => import('../views/login/toRegister.vue')
  },
  {
    path: '/login/ChangePassword',
    name: 'ChangePassword',
    component: () => import('../views/login/ChangePassword.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  base: '/',
})

export default router
