import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "amfe-flexible";    //适配
import '../src/assets/css/reset.css';
import '../src/assets/css/head.css';
import 'vant/lib/index.css';
import Vant from 'vant';
createApp(App).use(router).use(Vant).mount('#app')

