<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
  </router-view>
</template>
<script setup>
import VConsole from "@/assets/js/vconsole.min.js";
import { useRoute } from "vue-router";
const route = useRoute();
let yxid = AllgetQueryId("yxid");
if(yxid != null){
  localStorage.setItem("yxid", JSON.stringify(yxid));
}
function AllgetQueryId(variable) {
  var reg = new RegExp("(^|&)" + variable + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURI(r[2]);
  }
  return null;
}
//  new VConsole();
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.van-action-bar-button--danger {
  background-color: #eebf64 !important;
}
</style>
